import { type QueryValue, encodeQueryItem, joinURL } from 'ufo'
import { createOperationsGenerator } from '@nuxt/image/dist/runtime/utils/index'
import type { ProviderGetImage } from '@nuxt/image'
import { webshopSettings } from '~/store/webshopSettings'

const operationsGenerator = createOperationsGenerator({
    keyMap: {
        width: 'w',
        height: 'h',
        dpr: 'dpr',
        fit: 'fit',
        gravity: 'g',
        quality: 'q',
        format: 'f',
        sharpen: 'sharpen'
    },
    valueMap: {
        fit: {
            cover: 'cover',
            contain: 'contain',
            fill: 'scale-down',
            outside: 'crop',
            inside: 'pad'
        },
        gravity: {
            auto: 'auto',
            side: 'side'
        }
    },
    joinWith: ',',
    formatter: (key: string, val: QueryValue | QueryValue[]) => encodeQueryItem(key, val)
})
// https://developers.cloudflare.com/images/image-resizing/url-format/
export const getImage: ProviderGetImage = (src, options) => {
    const { public: { cloudflareImages } } = useRuntimeConfig()
    const { modifiers } = options
    const operations = operationsGenerator(modifiers as any)
    const baseUrl = `https://${webshopSettings().currentHost}`
    // https://<ZONE>/cdn-cgi/imagedelivery/<SOURCE-IMAGE>/<OPTIONS>
    const url = operations
        ? joinURL(baseUrl, 'cdn-cgi/imagedelivery/HoePsWrecgegeWaEivuBmg', src, operations)
        : joinURL(baseUrl, 'cdn-cgi/imagedelivery/HoePsWrecgegeWaEivuBmg', `${src}/public`)
    return {
        url: cloudflareImages ? url : `//${url.includes('foodticket-images') ? 'd2zv6vzmaqao5e' : 'diq1wl0g8dlu4'}.cloudfront.net${src}`
    }
}
