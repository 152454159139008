import revive_payload_client_uWMDqOWoTG from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2WJjnK8ebW from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_022REg2t90 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_65kcjUHvc8 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.21.2_vite@5.4.7_vue@3.5.8/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_9T4v1zA5ct from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jr5D0JQrN0 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_34VnVvbjUy from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FNbT5kIxkx from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_cLXxgdOCDv from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.2_typescript@5.1.3_vue@3.5.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_6D5hC4XryF from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.13.0_eslint@9.5.0_rollup@4.21.2_sass@1.77.8_stylelint@15.10.3_type_lcw7qrdm7aq4xgr4swsd5gky64/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/atlassian/pipelines/agent/build/.nuxt/pwa-icons-plugin.ts";
import pwa_client_GkM7612EUV from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.21.2_vite@5.4.7_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_62AylWkLq1 from "/opt/atlassian/pipelines/agent/build/modules/metaPixel/runtime/plugin.ts";
import plugin_hyqxmeg06o from "/opt/atlassian/pipelines/agent/build/modules/googleTrackers/runtime/plugin.ts";
import floating_vue_EIcJ7xiw0h from "/opt/atlassian/pipelines/agent/build/.nuxt/floating-vue.mjs";
import plugin_YZii481zTd from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.21.2_typescript@5.1.3_vue@3.5.8/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/atlassian/pipelines/agent/build/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_18HIMXCOtG from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.21.2_vue@3.5.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_TFvXwSrQZ0 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.21.2_vue@3.5.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import sentry_client_EBiao9zjjj from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_wozrirpyebuncn3c444pr5gvle/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/atlassian/pipelines/agent/build/.nuxt/sentry-client-config.mjs";
import plugin_e3p0eGMZYi from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_pinia@2.2.1_rollup@4.21.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_error_xteZhA1Hlb from "/opt/atlassian/pipelines/agent/build/plugins/apollo-error.ts";
import mitt_event_bus_LbQ9A4wOim from "/opt/atlassian/pipelines/agent/build/plugins/mitt-event-bus.ts";
import vue_final_modal_pML93k5qcp from "/opt/atlassian/pipelines/agent/build/plugins/vue-final-modal.ts";
 
function __PARALLEL_WRAPPER__ (plugin) {
  if (!plugin)
    return plugin;

    if(typeof plugin === 'function' && typeof plugin.parallel === 'undefined') {
      plugin.parallel = true
    }
}

__PARALLEL_WRAPPER__(_0_siteConfig_65kcjUHvc8)
__PARALLEL_WRAPPER__(components_plugin_KR1HBZs4kY)
__PARALLEL_WRAPPER__(pwa_icons_plugin_LnrhIJeMG7)
__PARALLEL_WRAPPER__(plugin_62AylWkLq1)
__PARALLEL_WRAPPER__(plugin_hyqxmeg06o)
__PARALLEL_WRAPPER__(floating_vue_EIcJ7xiw0h)
__PARALLEL_WRAPPER__(plugin_YZii481zTd)
__PARALLEL_WRAPPER__(formkitPlugin_pZqjah0RUG)
__PARALLEL_WRAPPER__(switch_locale_path_ssr_18HIMXCOtG)
__PARALLEL_WRAPPER__(i18n_TFvXwSrQZ0)
__PARALLEL_WRAPPER__(sentry_client_EBiao9zjjj)
__PARALLEL_WRAPPER__(sentry_client_config_o34nk2sJbg)
__PARALLEL_WRAPPER__(apollo_error_xteZhA1Hlb)
__PARALLEL_WRAPPER__(mitt_event_bus_LbQ9A4wOim)
__PARALLEL_WRAPPER__(vue_final_modal_pML93k5qcp)

export default [
  revive_payload_client_uWMDqOWoTG,
  unhead_2WJjnK8ebW,
  router_022REg2t90,
  _0_siteConfig_65kcjUHvc8,
  payload_client_9T4v1zA5ct,
  navigation_repaint_client_jr5D0JQrN0,
  check_outdated_build_client_34VnVvbjUy,
  chunk_reload_client_FNbT5kIxkx,
  plugin_vue3_cLXxgdOCDv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6D5hC4XryF,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_GkM7612EUV,
  plugin_62AylWkLq1,
  plugin_hyqxmeg06o,
  floating_vue_EIcJ7xiw0h,
  plugin_YZii481zTd,
  formkitPlugin_pZqjah0RUG,
  switch_locale_path_ssr_18HIMXCOtG,
  i18n_TFvXwSrQZ0,
  sentry_client_EBiao9zjjj,
  sentry_client_config_o34nk2sJbg,
  plugin_e3p0eGMZYi,
  apollo_error_xteZhA1Hlb,
  mitt_event_bus_LbQ9A4wOim,
  vue_final_modal_pML93k5qcp
]
