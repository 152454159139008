
// @ts-nocheck
import locale__opt_atlassian_pipelines_agent_build_locales_en_json from "../locales/en.json";
import locale__opt_atlassian_pipelines_agent_build_locales_fr_json from "../locales/fr.json";
import locale__opt_atlassian_pipelines_agent_build_locales_nl_json from "../locales/nl.json";
import locale__opt_atlassian_pipelines_agent_build_locales_de_json from "../locales/de.json";
import locale__opt_atlassian_pipelines_agent_build_locales_it_json from "../locales/it.json";


export const localeCodes =  [
  "en",
  "fr",
  "nl",
  "de",
  "it"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_en_json), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_fr_json), cache: true }],
  "nl": [{ key: "../locales/nl.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_nl_json), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_de_json), cache: true }],
  "it": [{ key: "../locales/it.json", load: () => Promise.resolve(locale__opt_atlassian_pipelines_agent_build_locales_it_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "French",
      "files": [
        "locales/fr.json"
      ]
    },
    {
      "code": "nl",
      "name": "Dutch",
      "iso": "nl-NL",
      "files": [
        "locales/nl.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "iso": "de-DE",
      "files": [
        "locales/de.json"
      ]
    },
    {
      "code": "it",
      "name": "Italian",
      "iso": "it-IT",
      "files": [
        "locales/it.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "iso": "en-US",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "name": "French",
    "files": [
      {
        "path": "locales/fr.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Dutch",
    "iso": "nl-NL",
    "files": [
      {
        "path": "locales/nl.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "iso": "de-DE",
    "files": [
      {
        "path": "locales/de.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italian",
    "iso": "it-IT",
    "files": [
      {
        "path": "locales/it.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
