import unescape from 'lodash.unescape'
import { hexToHSL } from '@/utils/color-utils'
import { availableLanguages } from '~/app/languages.options'
import type { ClientOpenOrderBuddy, DeliveryAreaOrderBuddy, Maybe, WebshopSettingsOrderBuddy } from '~/types/orderbuddy-types'
import { _colors } from '#tailwind-config/theme'
import type { WebshopSettingsState } from '~/types/local-types'
import { checkout } from '~/store/checkout'
import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'

export const webshopSettings = defineStore('webshopSettings', {
    state: (): WebshopSettingsState => <WebshopSettingsState>({
        currentLang: '',
        currentHost: null,
        translated: {},
        global: {}
    } as WebshopSettingsState),
    getters: {
        hasPrimaryColor: state => !!state?.global?.primaryColor,
        errorBackgroundClasses (): string {
            return this.hasPrimaryColor ? 'bg-white text-black' : 'bg-foodticket-primary text-white'
        },
        errorButtonClasses (): string {
            return this.hasPrimaryColor ? 'bg-primary text-white' : 'border-2 border-white text-white'
        },
        primaryColor: state => hexToHSL((state?.global?.primaryColor as string) || _colors['foodticket-primary']),
        primaryColorCssVariable (): string {
            return `:root { --color-primary: ${this.primaryColor.h},${this.primaryColor.s}%}`
        },
        logo: state => {
            return (fallbackVariant?: string) => {
                const fallBackUrl = `/svg/foodticket-logo${fallbackVariant ? `-${fallbackVariant}` : ''}.svg`
                const currentLogo = state?.global?.cssLogo?.path
                return currentLogo || fallBackUrl
            }
        },
        themeName: () => 'layout-a', // TODO: this should remain hardcoded until we offer different layouts. Also see feature/WF-38-make-theming-testcase-work
        defaultLanguage: state => state?.global?.defaultLanguage || 'nl',
        availableLanguages: state => {
            const configuredLanguages = state?.global?.hasLanguageSwitcher && state?.global?.availableLanguages ? [ ...state.global.availableLanguages ] || [] : []
            if (configuredLanguages.filter((lang: string) => lang === state?.global?.defaultLanguage).length === 0) {
                configuredLanguages.push(state?.global?.defaultLanguage || 'nl')
            }
            // TODO: availableLanguages in the backend should return an empty array or null, not an array with one empty value
            return availableLanguages.filter(lang => configuredLanguages.includes(lang.code))
        },
        filteredContentBlocks: state => {
            // TODO: remove this and reference blocks directly in the template when https://foodticket.atlassian.net/browse/WF-115 has been picked up
            return state?.translated?.contentBlocks?.filter(block => block.title)
        },
        deliveryHours: state => {
            // TODO: this does not use closedFrom and closedTill - you can use openingHoursRange for this
            if (state?.global?.openingHours) {
                // @ts-expect-error TODO: Fix TS issue: Vue: No overload matches this call
                return state?.global?.openingHours.filter((item: ClientOpenOrderBuddy) => item.dispatchType === DispatchTypeOrderBuddy.DELIVER)
            }
            return []
        },
        pickupHours: state => {
            if (state?.global?.openingHours) {
                // @ts-expect-error TODO: Fix TS issue: Vue: No overload matches this call
                return state?.global?.openingHours.filter((item: ClientOpenOrderBuddy) => item.dispatchType === DispatchTypeOrderBuddy.PICK_UP)
            }
            return []
        },
        isRemarksTurnedOn: state => {
            if (state?.global?.showProductRemarks) {
                return state.global.showProductRemarks
            } else if (state?.global?.showProductRemarks === undefined) {
                // Todo: right now it looks like when showProductRemarks has no defautl value, so it returns undefined. Update orderBuddy with this setting
                return false
            }
        },
        getSeoTitle: state => {
            if (state?.global?.seoTitle) {
                return unescape(state?.global?.seoTitle)
            }
            return null
        },
        isOneDispatchType: state => {
            return state?.global?.dispatchTypes?.length === 1
        },
        getCurrentDeliveryArea: (state: WebshopSettingsState): Maybe<DeliveryAreaOrderBuddy> | undefined | false => {
            // TODO: replace this when https://foodticket.atlassian.net/browse/CV-14 is done. Make sure the defaults are set correctly to 0 and not null.
            const zipCodes = state?.global?.delivery?.deliveryAreas || []
            const destination = checkout()?.input?.customer?.addressGroup?.postalCode
            if (zipCodes.length === 0 || (!destination || destination === '' || destination === undefined)) {
                return false
            } else {
                // NOTE: The code in this .find call is a 1:1 copy of code in the JS calculator package (src/modifiers/CalculateDeliveryCosts.ts:23)
                return zipCodes?.find(zipCode => {
                    const fromDigits = Number(zipCode?.start?.replace(/\D/g, ''))
                    const destinationDigits = Number(destination.replace(/\D/g, ''))
                    const fromLetters = zipCode?.start?.replace(/[^a-z]/gi, '').toUpperCase()
                    const destinationLetters = destination.replace(/[^a-z]/gi, '').toUpperCase()
                    const toDigits = zipCode?.end ? Number(zipCode?.end.replace(/\D/g, '')) : null
                    const toLetters = zipCode?.end ? zipCode?.end.replace(/[^a-z]/gi, '').toUpperCase() : null
                    if ((
                        toDigits && (destinationDigits < fromDigits || destinationDigits > toDigits)
                    )
                    || (!toDigits && (destinationDigits !== fromDigits))
                    ) {
                        return false
                    }
                    if (!fromLetters || !toLetters) {
                        return true
                    } else {
                        if (destinationDigits !== fromDigits && (toDigits && destinationDigits !== toDigits)) {
                            return true
                        }
                        const destIndex1 = destinationLetters[0]?.charCodeAt(0)
                        const destIndex2 = destinationLetters[1]?.charCodeAt(0)
                        const fromIndex1 = fromLetters[0]?.charCodeAt(0)
                        const fromIndex2 = fromLetters[1]?.charCodeAt(0)
                        const toIndex1 = toLetters ? toLetters[0]?.charCodeAt(0) : 'z'.charCodeAt(0)
                        const toIndex2 = toLetters ? toLetters[1]?.charCodeAt(0) : 'z'.charCodeAt(0)
                        if (destinationDigits === fromDigits) {
                            if (toDigits) {
                                return destIndex1 > fromIndex1 || (destIndex1 === fromIndex1 && destIndex2 >= fromIndex2)
                            } else {
                                return destinationLetters === fromLetters
                            }
                        }
                        if (toDigits && destinationDigits === toDigits) {
                            return destIndex1 < toIndex1 || (destIndex1 === toIndex1 && destIndex2 <= toIndex2)
                        }
                    }
                    return false
                })
            }
        },
        deliveryIsEnabled: state => state?.global?.dispatchTypes?.includes(DispatchTypeOrderBuddy.DELIVER),
        pickupIsEnabled: state => state?.global?.dispatchTypes?.includes(DispatchTypeOrderBuddy.PICK_UP),
        getDefaultDispatchType: state => {
            const hasMultipleDispatchTypes = state?.global?.dispatchTypes?.length > 1
            return !hasMultipleDispatchTypes ? DispatchTypeOrderBuddy.DELIVER : state.global.dispatchTypes[0] === DispatchTypeOrderBuddy.DELIVER ? DispatchTypeOrderBuddy.DELIVER : DispatchTypeOrderBuddy.PICK_UP
        }
    },
    actions: {
        setCurrentLang (lang: string) {
            this.currentLang = lang
        },
        setCurrentHost (host: string | null) {
            this.currentHost = host
        },
        setTranslated (data: WebshopSettingsOrderBuddy) {
            this.translated = data
        },
        setGlobalSettings (settings: WebshopSettingsOrderBuddy) {
            this.global = settings
        }
    }
})
