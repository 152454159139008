import { webshopSettings } from '~/store/webshopSettings'

function getCustomHeaders () {
    return {
        'Foodticket-Host': webshopSettings().currentHost,
        'Accept-Language': webshopSettings().currentLang
    }
}

export async function apiCallSsr (query: any, returnObject?: string, variables: object = {}, cache: boolean = true) {
    const { data } = await useAsyncQuery({
        query,
        variables,
        cache, // TODO: check if this is working again, as of writing this is broken
        context: {
            headers: {
                ...getCustomHeaders()
            }
        }
    })
    if (data?.value && returnObject) {
        return data?.value[returnObject]
    }
    return data?.value
}

export async function apiCallClient (query: any, returnObject?: string, variables: object = {}, cache: boolean = true) {
    const { resolveClient } = useApolloClient()
    const client = resolveClient()
    const { data } = await client.query({
        query,
        variables,
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
        context: {
            headers: {
                ...getCustomHeaders()
            }
        }
    })
    if (data && returnObject) {
        return data[returnObject]
    }
    return data?.value
}

export async function apiCall (query: any, returnObject?: string, variables: object = {}, cache: boolean = true) {
    if (process.server) {
        return await apiCallSsr(query, returnObject, variables, cache)
    }
    return await apiCallClient(query, returnObject, variables, cache)
}

export function apiCallMutation (query: any, variables: object = {}) {
    return useMutation(query, {
        variables,
        context: {
            headers: {
                ...getCustomHeaders()
            }
        }
    })
}
