export const availableLanguages = [
    {
        code: 'en',
        name: 'English',
        iso: 'en-US',
        file: 'en.json'
    },
    {
        code: 'fr',
        iso: 'fr-FR',
        name: 'French',
        file: 'fr.json'
    },
    {
        code: 'nl',
        name: 'Dutch',
        iso: 'nl-NL',
        file: 'nl.json'
    },
    {
        code: 'de',
        name: 'German',
        iso: 'de-DE',
        file: 'de.json'
    },
    {
        code: 'it',
        name: 'Italian',
        iso: 'it-IT',
        file: 'it.json'
    }
]
