import pickBy from 'lodash.pickby'
import mapValues from 'lodash.mapvalues'
import { hash } from 'ohash'
import type { CartItemsLocal, CartProductLocal } from '~/types/local-types'

// TODO: TS definitions
export function getCartProductId (product: any) {
    product = cleanProduct(product)
    product = Object.fromEntries(
        Object.entries(product).filter(([ key ]) => [ 'id', 'extras', 'note' ].includes(key))
    )
    return hash(product)
}

export function cleanProduct (product: CartProductLocal) {
    if (product?.extras) {
        // remove the extra items that are not true
        product.extras = mapValues(product.extras, (extra: CartItemsLocal) => {
            return mapValues(extra, extraItem => {
                return pickBy(extraItem, (value: any) => {
                    return Number(value.quantity) > 0
                })
            })
        })
        // remove the extras that are empty
        product.extras = pickBy(product.extras, (extra: CartItemsLocal) => {
            return Object.keys(extra.items).length > 0
        })
        if (product.extras && Object.keys(product.extras).length === 0) {
            delete product.extras
        }
    }
    return product
}
