import type { CategoryOrderBuddy, ExtraOrderBuddy, ItemOrderBuddy, Maybe, ProductOrderBuddy } from '~/types/orderbuddy-types'
import { apiCall } from '~/composables/apiCall'
import { categoriesQuery } from '~/graphql/queries/categories.query.gql'
import type { ShopSettings } from '~/types/local-types'

export const shop = defineStore('shop', {
    state: (): ShopSettings => <ShopSettings>({
        searchValue: '',
        allCategories: []
    } as ShopSettings),
    getters: {
        allProducts (state) {
            return state?.allCategories?.flatMap(({ products }) => products) || []
        },
        findProduct (state): ((productId: string | number) => Maybe<ProductOrderBuddy>) {
            return productId => state?.allProducts?.find(({ id }) => id === (typeof productId === 'string' ? Number.parseInt(productId) : productId)) || null
        },
        allExtras (state) {
            return state?.allProducts?.flatMap(({ extras }) => extras) || []
        },
        findExtra (state): ((extraId: string | number) => Maybe<ExtraOrderBuddy>) {
            return extraId => state?.allExtras?.find(({ id }) => id === (typeof extraId === 'string' ? Number.parseInt(extraId) : extraId)) || null
        },
        allExtrasItems (state) {
            return state?.allExtras?.flatMap(({ items }) => items) || []
        },
        findExtraItem (state): ((extraItemId: string) => Maybe<ItemOrderBuddy>) {
            return extraItemId => state?.allExtrasItems?.find(({ id }) => id === extraItemId) || null
        },
        filterProductsThatContainSearchValue (state): ((category: CategoryOrderBuddy) => Maybe<Array<ProductOrderBuddy>>) {
            return (category: CategoryOrderBuddy) => {
                const categoryMatches = category?.title?.toLowerCase().includes(state.searchValue!.toLowerCase()) || (category?.description && category.description.toLowerCase().includes(state.searchValue!.toLowerCase()))
                return category?.products?.filter(product => {
                    return categoryMatches || product?.title?.toLowerCase().includes(state.searchValue!.toLowerCase()) || (product?.description && product.description.toLowerCase().includes(state.searchValue!.toLowerCase()))
                }) || []
            }
        },
        filteredCategories (state): Maybe<Array<CategoryOrderBuddy>> {
            if (state.allCategories && state.allCategories.length > 0 && state.searchValue) {
                return state?.allCategories?.map((category: CategoryOrderBuddy) => ({
                    ...category,
                    products: this.filterProductsThatContainSearchValue(category)
                })).filter((category: CategoryOrderBuddy) => category?.products && category.products.length > 0) || []
            }
            return state.allCategories
        }
    },
    actions: {
        setSearchValue (data: string) {
            this.searchValue = data
        },
        async fetchAllCategories (dispatchType: string) {
            if (this.allCategories?.length === 0) {
                const data = await apiCall(categoriesQuery, 'menu', {
                    dispatchType
                })
                this.allCategories = data.categories
                return data.categories
            }
        },
        async setAllCategories (categories: Array<CategoryOrderBuddy>) {
            if (this.allCategories?.length === 0) {
                this.allCategories = categories.filter(category => category.products?.length)
                return categories.filter(category => category.products?.length)
            }
        }
    }
})
